import * as React from 'react'
import { graphql } from 'gatsby'
import './index.scss'
import Header from '../../components/header/header'
import WithHeaderLayour from '../../layouts/with-header'
import Footer from '../../components/footer/footer'
import Hero from '../../components/hero/hero'
import Quotation from '../../components/quotation/quotation'
import PageHeader from '../../components/page-header/page-header'
import SeloAbracam from '../../components/selo-abracam/selo-abracam'

const IndexPage = ({ data, location }) => {
  const nomeDaEmpresa = data.contentfulGeral.nomeDaEmpresa
  const telefone = data.contentfulGeral.telefone

  return (
    <WithHeaderLayour>
      <Header currentPath={location?.pathname} />
      <PageHeader
        headline={`Chip Viagem`}
        description="O chip internacional é a opção favorita de quem não abre mão da liberdade fundamental de se conectar com quem quiser ou precisar, esteja onde estiver."
      />
      <div className="container" style={{ padding: '70px 0' }}>
        <h2>ECONOMIA</h2>
        <div>
          Em parceria com a provedora Lycamobile, a maior operadora móvel
          virtual (MVNO) do mundo, oferecemos um serviço de qualidade sem pesar
          em seu bolso. Você terá, à sua disposição, um chip Triple SIM com os
          recursos que mais se adequarem a seu perfil e necessidades.
        </div>
        <h2>COBERTURA</h2>
        <div>
          Dos grandes centros urbanos aos vilarejos mais remotos, não te
          deixamos na mão quando o assunto é conectividade. Veja abaixo.
        </div>
        <h2>USO ILIMITADO</h2>
        <div>
          Nossos planos incluem ligações ilimitadas para o Brasil e dentro dos
          EUA (fixo e celular), com internet ilimitada 4G.
        </div>
      </div>
      <SeloAbracam />
      <Footer />
    </WithHeaderLayour>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query ChipViagemQuery {
    contentfulGeral {
      nomeDaEmpresa
      telefone
    }
  }
`
